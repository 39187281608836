




































































































































































































import { parseISO } from 'date-fns'
import { Component, Vue } from 'vue-property-decorator'

import { daysBetween } from './dateUtils'
import { tyoskentelyjaksotTaulukkoData } from './tyoskentelyjaksot-offline-data'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaPoissaolonSyyt from '@/components/poissaolon-syyt/poissaolon-syyt.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import TyokertymalaskuriModal from '@/components/tyokertymalaskuri/tyokertymalaskuri-modal.vue'
import TyoskentelyjaksotBarChart from '@/components/tyoskentelyjaksot-bar-chart.vue'
import ElsaVanhaAsetusVaroitus from '@/components/vanha-asetus-varoitus/vanha-asetus-varoitus.vue'
import {
  HyvaksiluettavatCounterData,
  TyokertymaLaskuriPoissaolo,
  TyokertymaLaskuriTyoskentelyjakso,
  TyokertymaLaskuriTyoskentelyjaksotTable,
  TyoskentelyjaksotTilastotKaytannonKoulutus,
  TyoskentelyjaksotTilastotTyoskentelyjaksot
} from '@/types'
import { confirmExitWithTexts } from '@/utils/confirm'
import { KaytannonKoulutusTyyppi } from '@/utils/constants'
import { sortByDateDesc } from '@/utils/date'
import { toastFail, toastSuccess } from '@/utils/toast'
import { ajankohtaLabel } from '@/utils/tyoskentelyjakso'
import { validateTyoskentelyaika } from '@/views/tyokertymalaskuri/overlapping-tyoskentelyjakso-validator'
import {
  calculateAmountOfReducedDaysAndUpdateHyvaksiluettavatCounter,
  getHyvaksiluettavatPerYearMap
} from '@/views/tyokertymalaskuri/tyoskentelyjakson-pituus-counter'

@Component({
  components: {
    TyokertymalaskuriModal,
    ElsaButton,
    ElsaFormGroup,
    ElsaPopover,
    TyoskentelyjaksotBarChart,
    ElsaPoissaolonSyyt,
    ElsaVanhaAsetusVaroitus
  }
})
export default class Tyokertymalaskuri extends Vue {
  items = [
    {
      text: this.$t('kirjautuminen-linkki'),
      to: { name: 'login' }
    },
    {
      text: this.$t('tyokertymalaskuri'),
      active: true
    }
  ]
  tyoskentelyjaksotTaulukko: TyokertymaLaskuriTyoskentelyjaksotTable =
    tyoskentelyjaksotTaulukkoData
  fields = [
    {
      key: 'tyoskentelypaikkaLabel',
      label: this.$t('tyoskentelypaikka'),
      sortable: true
    },
    {
      key: 'ajankohtaDate',
      label: this.$t('ajankohta'),
      sortable: true
    },
    {
      key: 'tyoskentelyaikaLabel',
      label: this.$t('tyokertyma'),
      sortable: true
    },
    {
      key: 'osaaikaprosenttiLabel',
      label: this.$t('tyoaika'),
      sortable: true
    },
    {
      key: 'keskeytyksetLength',
      label: this.$t('poissaolot'),
      sortable: true
    }
  ]
  lisaaTyoskentelyjaksoFormModal = false
  editTyoskentelyjakso: TyokertymaLaskuriTyoskentelyjakso | null = null
  tyoskentelyjaksotLocalStorageKey = 'laskuri-tyoskentelyjaksot'
  showChart = false
  chartKey = 0
  showDetails = false

  async mounted() {
    this.loadFromLocalStorage()
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  }

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  }

  refreshChart() {
    this.chartKey += 1
  }

  get tyoskentelyjaksot() {
    if (this.tyoskentelyjaksotTaulukko) {
      return this.tyoskentelyjaksotTaulukko.tyoskentelyjaksot
    } else {
      return []
    }
  }

  get tilastot() {
    if (this.tyoskentelyjaksotTaulukko) {
      return this.tyoskentelyjaksotTaulukko.tilastot
    } else {
      return undefined
    }
  }

  get tilastotKaytannonKoulutus() {
    if (this.tilastot) {
      return this.tilastot.kaytannonKoulutus
    } else {
      return []
    }
  }

  get tilastotTyoskentelyjaksot() {
    if (this.tilastot && this.showChart) {
      return this.tilastot.tyoskentelyjaksot
    } else {
      return []
    }
  }

  get tilastotKaytannonKoulutusSorted() {
    return [
      this.tilastotKaytannonKoulutus.find(
        (kk) => kk.kaytannonKoulutus === KaytannonKoulutusTyyppi.OMAN_ERIKOISALAN_KOULUTUS
      ),
      this.tilastotKaytannonKoulutus.find(
        (kk) => kk.kaytannonKoulutus === KaytannonKoulutusTyyppi.MUU_ERIKOISALA
      ),
      this.tilastotKaytannonKoulutus.find(
        (kk) =>
          kk.kaytannonKoulutus === KaytannonKoulutusTyyppi.KAHDEN_VUODEN_KLIININEN_TYOKOKEMUS
      ),
      this.tilastotKaytannonKoulutus.find(
        (kk) => kk.kaytannonKoulutus === KaytannonKoulutusTyyppi.TERVEYSKESKUSTYO
      )
    ].filter((kk) => kk !== null) as TyoskentelyjaksotTilastotKaytannonKoulutus[]
  }

  get donutSeries() {
    const hasTyoskentelyjaksot = this.tyoskentelyjaksot.length > 0
    return hasTyoskentelyjaksot
      ? this.tilastotKaytannonKoulutusSorted.map((kk) => kk.suoritettu)
      : [100]
  }

  get donutOptions() {
    const hasTyoskentelyjaksot = this.tyoskentelyjaksot.length > 0
    return {
      colors: hasTyoskentelyjaksot
        ? ['#097BB9', '#FF8B06', '#808080', '#FFB406']
        : ['#E8E9EC', '#E8E9EC', '#E8E9EC', '#E8E9EC'],
      labels: [
        `${this.$t('oma-erikoisala')}: ${
          this.tilastotKaytannonKoulutusSorted[0]?.suoritettu
            ? this.$duration(this.tilastotKaytannonKoulutusSorted[0].suoritettu)
            : '0'
        }`,
        `${this.$t('muu-erikoisala')}: ${
          this.tilastotKaytannonKoulutusSorted[1]?.suoritettu
            ? this.$duration(this.tilastotKaytannonKoulutusSorted[1].suoritettu)
            : '0'
        }`,
        `${this.$t('kahden-vuoden-kliininen-tyokokemus')}: ${
          this.tilastotKaytannonKoulutusSorted[2]?.suoritettu
            ? this.$duration(this.tilastotKaytannonKoulutusSorted[2].suoritettu)
            : '0'
        }`,
        `${this.$t('terveyskeskustyo')}: ${
          this.tilastotKaytannonKoulutusSorted[3]?.suoritettu
            ? this.$duration(this.tilastotKaytannonKoulutusSorted[3].suoritettu)
            : '0'
        }`
      ],
      legend: {
        fontSize: '13px',
        fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
        onItemClick: {
          toggleDataSeries: false
        },
        onItemHover: {
          highlightDataSeries: false
        },
        offsetY: '1px'
      },
      chart: {
        type: 'donut',
        animations: {
          enabled: false
        }
      },
      dataLabels: {
        formatter: function (val: number) {
          return Math.round(val) + '%'
        },
        style: {
          fontSize: '8px',
          fontFamily: 'Montserrat, Helvetica, Arial, sans-serif'
        },
        dropShadow: {
          enabled: false
        }
      },
      plotOptions: {
        pie: {
          expandOnClick: false
        }
      },
      stroke: {
        show: false
      },
      states: {
        hover: {
          filter: {
            type: 'normal'
          }
        },
        active: {
          filter: {
            type: 'normal'
          }
        }
      },
      tooltip: {
        enabled: false
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            legend: {
              position: 'bottom',
              offsetY: 0
            }
          }
        }
      ]
    }
  }

  get tyoskentelyjaksotFormatted() {
    const tilastotTyoskentelyjaksotMap = this.tilastotTyoskentelyjaksot.reduce(
      (
        result: { [key: number]: number },
        tyoskentelyjakso: TyoskentelyjaksotTilastotTyoskentelyjaksot
      ) => {
        result[tyoskentelyjakso.id] = tyoskentelyjakso.suoritettu
        return result
      },
      {}
    )

    return this.tyoskentelyjaksot
      .map((tj, index) => ({
        ...tj,
        tyoskentelypaikkaLabel: tj.tyoskentelypaikka.nimi,
        ajankohtaDate: tj.alkamispaiva ? parseISO(tj.alkamispaiva) : null,
        ajankohta: ajankohtaLabel(this, tj),
        tyoskentelyaikaLabel: this.$duration(tilastotTyoskentelyjaksotMap[index + 1]),
        osaaikaprosenttiLabel: `${
          String(tj.kaytannonKoulutus) !==
          String(KaytannonKoulutusTyyppi.KAHDEN_VUODEN_KLIININEN_TYOKOKEMUS)
            ? tj.osaaikaprosentti
            : tj.kahdenvuodenosaaikaprosentti
        } %`,
        keskeytykset: tj.poissaolot,
        keskeytyksetLength: tj.poissaolot.length,
        _showDetails: this.showDetails
      }))
      .sort((a, b) => sortByDateDesc(a.paattymispaiva, b.paattymispaiva))
  }

  openLisaaTyoskentelyjaksoFormModal() {
    this.editTyoskentelyjakso = null
    this.lisaaTyoskentelyjaksoFormModal = true
  }

  openTyoskentelyjaksoFormModalForEdit(tj: TyokertymaLaskuriTyoskentelyjakso) {
    this.editTyoskentelyjakso = tj
    this.lisaaTyoskentelyjaksoFormModal = true
  }

  async onSubmit(formData: TyokertymaLaskuriTyoskentelyjakso) {
    const isValid = validateTyoskentelyaika(
      formData.id > 0 ? formData.id : null,
      parseISO(formData.alkamispaiva),
      parseISO(formData.paattymispaiva || this.getISODateNow()),
      this.tyoskentelyjaksotTaulukko.tyoskentelyjaksot,
      formData.kaytannonKoulutus !==
        String(KaytannonKoulutusTyyppi.KAHDEN_VUODEN_KLIININEN_TYOKOKEMUS)
        ? formData.osaaikaprosentti
        : formData.kahdenvuodenosaaikaprosentti
    )
    if (!isValid) {
      toastFail(this, this.$t('paallekkaiset-tyoskentelyjaksot-yhteenlaskettu-tyoaika-virhe'))
      return
    }

    if (formData.id > 0) {
      const index: number = this.tyoskentelyjaksotTaulukko.tyoskentelyjaksot.findIndex(
        (item) => item.id === formData.id
      )
      this.tyoskentelyjaksotTaulukko.tyoskentelyjaksot[index] = formData
      toastSuccess(this, this.$t('tyoskentelyjakson-muutokset-tallennettu'))
    } else {
      const maxId = this.tyoskentelyjaksotTaulukko.tyoskentelyjaksot.reduce(
        (max, item) => Math.max(max, item.id),
        0
      )
      formData.id = maxId + 1
      this.tyoskentelyjaksotTaulukko.tyoskentelyjaksot.push(formData)
      toastSuccess(this, this.$t('tyoskentelyjakso-lisatty'))
    }
    this.saveToLocalStorage()
    this.lisaaTyoskentelyjaksoFormModal = false
  }

  onDelete(id: number) {
    const indexToRemove: number = this.tyoskentelyjaksotTaulukko.tyoskentelyjaksot.findIndex(
      (item) => item.id === id
    )
    if (indexToRemove !== -1) {
      this.tyoskentelyjaksotTaulukko.tyoskentelyjaksot.splice(indexToRemove, 1)
    }
    this.saveToLocalStorage()
    this.editTyoskentelyjakso = null
    this.lisaaTyoskentelyjaksoFormModal = false
    toastSuccess(this, this.$t('tyoskentelyjakso-poistettu'))
  }

  saveToLocalStorage() {
    localStorage.setItem(
      this.tyoskentelyjaksotLocalStorageKey,
      JSON.stringify(this.tyoskentelyjaksotTaulukko.tyoskentelyjaksot)
    )
    this.laskeTilastot()
  }

  loadFromLocalStorage() {
    const savedData = localStorage.getItem(this.tyoskentelyjaksotLocalStorageKey)
    if (savedData) {
      this.tyoskentelyjaksotTaulukko.tyoskentelyjaksot = JSON.parse(savedData)
      this.laskeTilastot()
    }
  }

  laskeTilastot() {
    this.showChart = false
    const vahennettavatPaivat = this.getVahennettavatPaivat(
      JSON.parse(JSON.stringify(this.tyoskentelyjaksotTaulukko.tyoskentelyjaksot))
    )

    this.tyoskentelyjaksotTaulukko.tilastot = {
      arvioErikoistumiseenHyvaksyttavista: 0,
      arvioPuuttuvastaKoulutuksesta: 0,
      kaytannonKoulutus: [
        {
          kaytannonKoulutus: KaytannonKoulutusTyyppi.OMAN_ERIKOISALAN_KOULUTUS,
          suoritettu: 0
        },
        {
          kaytannonKoulutus: KaytannonKoulutusTyyppi.MUU_ERIKOISALA,
          suoritettu: 0
        },
        {
          kaytannonKoulutus: KaytannonKoulutusTyyppi.KAHDEN_VUODEN_KLIININEN_TYOKOKEMUS,
          suoritettu: 0
        },
        {
          kaytannonKoulutus: KaytannonKoulutusTyyppi.TERVEYSKESKUSTYO,
          suoritettu: 0
        }
      ],
      koulutustyypit: {
        terveyskeskusVaadittuVahintaan: 0,
        terveyskeskusMaksimipituus: 0,
        terveyskeskusSuoritettu: 0,
        yliopistosairaalaVaadittuVahintaan: 0,
        yliopistosairaalaSuoritettu: 0,
        yliopistosairaaloidenUlkopuolinenVaadittuVahintaan: 0,
        yliopistosairaaloidenUlkopuolinenSuoritettu: 0,
        yhteensaVaadittuVahintaan: 0,
        yhteensaSuoritettu: 0
      },
      poissaoloaikaYhteensa: 0,
      tyokertymaYhteensa: 0,
      tyoskentelyaikaYhteensa: 0,
      tyoskentelyjaksot: []
    }
    this.tyoskentelyjaksotTaulukko.tyoskentelyjaksot.forEach(
      (tj: TyokertymaLaskuriTyoskentelyjakso, index: number) => {
        const vahennettavat = vahennettavatPaivat.get(tj.id) || 0

        let tyoskentelyaika = 0
        let tyokertyma = 0

        if (vahennettavat > 0) {
          tyokertyma =
            daysBetween(
              parseISO(tj.alkamispaiva),
              parseISO(tj.paattymispaiva || this.getISODateNow())
            ) - vahennettavat
        } else {
          tyokertyma = daysBetween(
            parseISO(tj.alkamispaiva),
            parseISO(tj.paattymispaiva || this.getISODateNow())
          )
        }

        const osaaikaProsentti =
          (String(tj.kaytannonKoulutus) !==
          String(KaytannonKoulutusTyyppi.KAHDEN_VUODEN_KLIININEN_TYOKOKEMUS)
            ? tj.osaaikaprosentti
            : tj.kahdenvuodenosaaikaprosentti) / 100

        const tyokertymaOsaajalla = tyokertyma * osaaikaProsentti

        tyoskentelyaika = daysBetween(
          parseISO(tj.alkamispaiva),
          parseISO(tj.paattymispaiva || this.getISODateNow())
        )
        const tyoskentelyaikaOsaajalla = tyoskentelyaika * osaaikaProsentti

        // let poissaoloaikaYhteensa = 0
        // tj.poissaolot.forEach((poissaolo: TyokertymaLaskuriPoissaolo) => {
        //   if (poissaolo.alkamispaiva && poissaolo.paattymispaiva) {
        //     const startDate = parseISO(poissaolo.alkamispaiva)
        //     const endDate = parseISO(poissaolo.paattymispaiva)
        //     const daysDifference = daysBetween(startDate, endDate)
        //     poissaoloaikaYhteensa +=
        //       ((poissaolo.poissaoloprosentti || 100) / 100) * daysDifference * osaaikaProsentti
        //   }
        // })

        this.tyoskentelyjaksotTaulukko.tilastot.tyoskentelyaikaYhteensa +=
          tyoskentelyaikaOsaajalla
        this.tyoskentelyjaksotTaulukko.tilastot.poissaoloaikaYhteensa +=
          vahennettavat * osaaikaProsentti
        this.tyoskentelyjaksotTaulukko.tilastot.tyokertymaYhteensa += tyokertymaOsaajalla
        switch (tj.kaytannonKoulutus) {
          case KaytannonKoulutusTyyppi.OMAN_ERIKOISALAN_KOULUTUS:
            this.tyoskentelyjaksotTaulukko.tilastot.kaytannonKoulutus[0].suoritettu +=
              tyokertymaOsaajalla
            break
          case KaytannonKoulutusTyyppi.MUU_ERIKOISALA:
            this.tyoskentelyjaksotTaulukko.tilastot.kaytannonKoulutus[1].suoritettu +=
              tyokertymaOsaajalla
            break
          case KaytannonKoulutusTyyppi.KAHDEN_VUODEN_KLIININEN_TYOKOKEMUS:
            this.tyoskentelyjaksotTaulukko.tilastot.kaytannonKoulutus[2].suoritettu +=
              tyokertymaOsaajalla
            break
          case KaytannonKoulutusTyyppi.TERVEYSKESKUSTYO:
            this.tyoskentelyjaksotTaulukko.tilastot.kaytannonKoulutus[3].suoritettu +=
              tyokertymaOsaajalla
            break
        }
        this.tyoskentelyjaksotTaulukko.tilastot.tyoskentelyjaksot.push({
          id: index + 1,
          suoritettu: tyokertymaOsaajalla
        })
      }
    )
    this.showChart = true
    this.refreshChart()
  }

  getVahennettavatPaivat(
    tyoskentelyjaksot: TyokertymaLaskuriTyoskentelyjakso[]
  ): Map<number, number> {
    const result = new Map<number, number>()
    const hyvaksiluettavatCounter: HyvaksiluettavatCounterData = {
      hyvaksiluettavatDays: new Map<string, number>(),
      hyvaksiluettavatPerYearMap: getHyvaksiluettavatPerYearMap(tyoskentelyjaksot)
    }
    const now = new Date()
    tyoskentelyjaksot
      .flatMap((jakso: TyokertymaLaskuriTyoskentelyjakso) =>
        jakso.poissaolot
          .filter((poissaolo) => poissaolo.alkamispaiva)
          .map((poissaolo: TyokertymaLaskuriPoissaolo) => {
            return {
              ...poissaolo,
              tyoskentelyjakso: jakso,
              tyoskentelyjaksoId: jakso.id,
              poissaolonSyyId: poissaolo.poissaolonSyy.id || 0
            }
          })
      )
      .sort((a, b) => parseISO(a.alkamispaiva).getTime() - parseISO(b.alkamispaiva).getTime())
      .forEach((keskeytys: TyokertymaLaskuriPoissaolo) => {
        const tyoskentelyjaksoFactor =
          (keskeytys.tyoskentelyjakso?.osaaikaprosentti ?? 100) / 100.0

        const endDate = keskeytys.tyoskentelyjakso?.paattymispaiva
          ? parseISO(keskeytys.tyoskentelyjakso.paattymispaiva)
          : now
        const effectiveEndDate = endDate.getTime() > now.getTime() ? now : endDate

        const amountOfReducedDays = calculateAmountOfReducedDaysAndUpdateHyvaksiluettavatCounter(
          keskeytys,
          tyoskentelyjaksoFactor,
          hyvaksiluettavatCounter,
          effectiveEndDate
        )

        const tyoskentelyjaksoId = keskeytys.tyoskentelyjakso.id
        result.set(
          tyoskentelyjaksoId,
          (result.get(tyoskentelyjaksoId) ?? 0) + amountOfReducedDays
        )
      })
    return result
  }

  getISODateNow() {
    const date = new Date()
    return date.toISOString().split('T')[0]
  }

  printPage() {
    this.showDetails = true
    setTimeout(() => {
      const styleElement = document.createElement('style')
      document.head.appendChild(styleElement)
      window.print()
      document.head.removeChild(styleElement)
    }, 500)
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    const handleNavigation = async () => {
      const shouldProceed = await confirmExitWithTexts(
        this,
        this.$t('vahvista-poistuminen') as string,
        this.$t('tyokertymalaskuri-poistuminen-ohje') as string,
        this.$t('poistu-laskurista') as string,
        this.$t('peruuta') as string
      )
      if (shouldProceed) {
        localStorage.removeItem(this.tyoskentelyjaksotLocalStorageKey)
        window.location.href = '/kirjautuminen'
      } else {
        next(false)
      }
    }
    handleNavigation().catch(() => {
      next(false)
    })
  }

  async handleBeforeUnload() {
    if (process.env.NODE_ENV !== 'development') {
      localStorage.removeItem(this.tyoskentelyjaksotLocalStorageKey)
    }
  }
}
