





























































































































































































































































































































































































































import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { email, required, requiredIf } from 'vuelidate/lib/validators'

import { getVastuuhenkilonArvioLomake } from '@/api/erikoistuva'
import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import AsiakirjatUpload from '@/components/asiakirjat/asiakirjat-upload.vue'
import ElsaButton from '@/components/button/button.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import KoejaksonVaiheAllekirjoitukset from '@/components/koejakson-vaiheet/koejakson-vaihe-allekirjoitukset.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import store from '@/store'
import {
  Asiakirja,
  Koejakso,
  KoejaksonVaiheAllekirjoitus,
  KoejaksonVaiheButtonStates,
  Opintooikeus,
  VastuuhenkilonArvioLomake,
  VastuuhenkilonArvioLomakeErikoistuva
} from '@/types'
import { LomakeTilat, phoneNumber } from '@/utils/constants'
import { mapFiles } from '@/utils/fileMapper'
import { checkCurrentRouteAndRedirect } from '@/utils/functions'
import * as allekirjoituksetHelper from '@/utils/koejaksonVaiheAllekirjoitusMapper'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  mixins: [validationMixin],
  components: {
    AsiakirjatContent,
    AsiakirjatUpload,
    ErikoistuvaDetails,
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormMultiselect,
    ElsaButton,
    ElsaConfirmationModal,
    ElsaPopover,
    KoejaksonVaiheAllekirjoitukset
  }
})
export default class ErikoistuvaArviointilomakeVastuuhenkilonArvio extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koejakso'),
      to: { name: 'koejakso' }
    },
    {
      text: this.$t('koejakson-vastuuhenkilon-arvio'),
      active: true
    }
  ]
  buttonStates: KoejaksonVaiheButtonStates = {
    primaryButtonLoading: false,
    secondaryButtonLoading: false
  }
  koejaksonVaihe = this.$t('vastuuhenkilon-arvio')

  form: VastuuhenkilonArvioLomake = {
    erikoistuvanErikoisala: this.account.erikoistuvaLaakari.erikoisalaNimi,
    erikoistuvanNimi: `${this.account.firstName} ${this.account.lastName}`,
    erikoistuvanOpiskelijatunnus: this.account.erikoistuvaLaakari.opiskelijatunnus,
    erikoistuvanYliopisto: this.account.erikoistuvaLaakari.yliopisto,
    erikoistuvanSahkoposti: this.account.erikoistuvaLaakari.sahkoposti,
    erikoistuvanPuhelinnumero: this.account.erikoistuvaLaakari.puhelinnumero,
    muutOpintooikeudet: [],
    paataOpintooikeudet: false,
    id: null,
    muokkauspaiva: '',
    koejaksoHyvaksytty: null,
    vastuuhenkilo: null,
    vastuuhenkiloAllekirjoittanut: null,
    hylattyArviointiKaytyLapiKeskustellen: null,
    vastuuhenkilonKuittausaika: undefined
  }
  formData: VastuuhenkilonArvioLomakeErikoistuva = {
    muutOpintooikeudet: [],
    paataOpintooikeudet: false,
    koulutussopimusHyvaksytty: true,
    vastuuhenkilo: null,
    tyoskentelyjaksoLiitetty: false,
    tyoskentelyjaksonPituusRiittava: false,
    tyotodistusLiitetty: false
  }
  loading = true
  muutOpintooikeudet: Opintooikeus[] = []
  muutOpintooikeudetEnabled = false
  koulutussopimuksenHyvaksynta = false
  koulutussuunnitelmaHyvaksytty = false
  reservedAsiakirjaNimetMutable: string[] = []
  addedFiles: File[] = []
  newAsiakirjatMapped: Asiakirja[] = []
  deletedAsiakirjat: Asiakirja[] = []

  validations() {
    return {
      form: {
        erikoistuvanSahkoposti: {
          required,
          email
        },
        erikoistuvanPuhelinnumero: {
          required,
          phoneNumber
        },
        paataOpintooikeudet: {
          checked: (value: boolean) => {
            return !this.muutOpintooikeudetEnabled || value === true
          },
          required: requiredIf(() => {
            return this.muutOpintooikeudetEnabled
          })
        },
        koulutussopimusHyvaksytty: {
          checked: (value: boolean) => {
            return this.koulutussopimuksenHyvaksynta || value === true
          },
          required: requiredIf(() => {
            return !this.koulutussopimuksenHyvaksynta
          })
        }
      },
      koulutussuunnitelmaHyvaksytty: {
        checked: (value: boolean) => value === true
      }
    }
  }

  get account() {
    return store.getters['auth/account']
  }

  get editable() {
    if (this.account.impersonated) {
      return false
    }
    return (
      this.koejaksoData.vastuuhenkilonArvionTila === LomakeTilat.UUSI ||
      this.koejaksoData.vastuuhenkilonArvionTila === LomakeTilat.PALAUTETTU_KORJATTAVAKSI
    )
  }

  get showReturned() {
    return this.koejaksoData.vastuuhenkilonArvionTila === LomakeTilat.PALAUTETTU_KORJATTAVAKSI
  }

  get waitingForSignatures() {
    return (
      this.koejaksoData.vastuuhenkilonArvionTila ===
      LomakeTilat.ODOTTAA_VASTUUHENKILON_ALLEKIRJOITUSTA
    )
  }

  get korjausehdotus() {
    return this.koejaksoData.vastuuhenkilonArvio?.virkailijanKorjausehdotus != null
      ? this.koejaksoData.vastuuhenkilonArvio?.virkailijanKorjausehdotus
      : this.koejaksoData.vastuuhenkilonArvio?.vastuuhenkilonKorjausehdotus
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? !$error : null
  }

  validateKoulutussuunnitelmaHyvaksytty() {
    const { $dirty, $error } = this.$v.koulutussuunnitelmaHyvaksytty as any
    return $dirty ? !$error : null
  }

  get waitingForAcceptance() {
    return (
      this.koejaksoData.vastuuhenkilonArvionTila === LomakeTilat.ODOTTAA_HYVAKSYNTAA ||
      this.koejaksoData.vastuuhenkilonArvionTila ===
        LomakeTilat.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA
    )
  }

  get acceptedByEveryone() {
    return this.koejaksoData.vastuuhenkilonArvionTila === LomakeTilat.ALLEKIRJOITETTU
  }

  get koejaksoData(): Koejakso {
    return store.getters['erikoistuva/koejakso']
  }

  setKoejaksoData() {
    if (this.koejaksoData.vastuuhenkilonArvio) {
      this.form = this.koejaksoData.vastuuhenkilonArvio
    }
  }

  get allekirjoitukset(): KoejaksonVaiheAllekirjoitus[] {
    const allekirjoitusErikoistuva = allekirjoituksetHelper.mapAllekirjoitusErikoistuva(
      this,
      this.form?.erikoistuvanNimi,
      this.form?.erikoistuvanKuittausaika
    )
    const allekirjoitusVastuuhenkilo = allekirjoituksetHelper.mapAllekirjoitusVastuuhenkilo(
      this.form.vastuuhenkilo
    ) as KoejaksonVaiheAllekirjoitus

    return [allekirjoitusVastuuhenkilo, allekirjoitusErikoistuva].filter(
      (a): a is KoejaksonVaiheAllekirjoitus => a !== null
    )
  }

  get hasTyoskentelyjaksoErrors() {
    return (
      this.formData.tyoskentelyjaksoLiitetty === false ||
      this.formData.tyoskentelyjaksonPituusRiittava === false ||
      this.formData.tyotodistusLiitetty === false
    )
  }

  optionDisplayName(option: any) {
    return option.nimike ? option.nimi + ', ' + option.nimike : option.nimi
  }

  hideModal(id: string) {
    return this.$bvModal.hide(id)
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    this.$v.koulutussuunnitelmaHyvaksytty.$touch()
    return !this.$v.$anyError
  }

  onConfirm(modalId: string) {
    if (!this.validateForm()) {
      return
    }
    return this.$bvModal.show(modalId)
  }

  async onSend() {
    try {
      this.buttonStates.primaryButtonLoading = true
      const formData = new FormData()
      formData.append('vastuuhenkilonArvioJson', JSON.stringify(this.form))
      this.addedFiles.forEach((file: File) => formData.append('asiakirjaFiles', file, file.name))
      if (this.koejaksoData.vastuuhenkilonArvionTila === LomakeTilat.PALAUTETTU_KORJATTAVAKSI) {
        formData.append(
          'deletedAsiakirjaIdsJson',
          JSON.stringify(this.deletedAsiakirjat.map((a) => a.id))
        )
        await store.dispatch('erikoistuva/putVastuuhenkilonArvio', formData)
      } else {
        await store.dispatch('erikoistuva/postVastuuhenkilonArvio', formData)
      }
      this.buttonStates.primaryButtonLoading = false
      toastSuccess(this, this.$t('vastuuhenkilon-arvio-lahetetty-onnistuneesti'))
      this.$emit('skipRouteExitConfirm', true)
      checkCurrentRouteAndRedirect(this.$router, '/koejakso')
    } catch (err) {
      toastFail(this, this.$t('vastuuhenkilon-arvio-lahetys-epaonnistui'))
    }
  }

  async mounted() {
    this.loading = true
    if (!this.koejaksoData) {
      await store.dispatch('erikoistuva/getKoejakso')
    }
    this.setKoejaksoData()
    this.formData = (await getVastuuhenkilonArvioLomake()).data
    this.muutOpintooikeudet = this.formData.muutOpintooikeudet
    this.koulutussopimuksenHyvaksynta = this.formData.koulutussopimusHyvaksytty
    this.muutOpintooikeudetEnabled =
      this.muutOpintooikeudet != null && this.muutOpintooikeudet.length > 0

    if (this.editable) {
      this.form.vastuuhenkilo = this.formData.vastuuhenkilo
      this.form.erikoistuvanSahkoposti = this.account.erikoistuvaLaakari.sahkoposti
      this.form.erikoistuvanPuhelinnumero = this.account.erikoistuvaLaakari.puhelinnumero

      this.reservedAsiakirjaNimetMutable = (
        await axios.get('erikoistuva-laakari/asiakirjat/nimet')
      ).data
    }

    this.loading = false
  }

  get existingFileNamesInCurrentView() {
    return this.asiakirjatTableItems?.map((item) => item.nimi)
  }

  get existingFileNamesInOtherViews() {
    return this.reservedAsiakirjaNimetMutable?.filter(
      (nimi) =>
        !this.existingFileNamesInCurrentView.includes(nimi) &&
        !this.deletedAsiakirjat.map((deleted) => deleted.nimi).includes(nimi)
    )
  }

  get asiakirjatTableItems() {
    return [...this.newAsiakirjatMapped, ...this.asiakirjatExcludingDeleted()]
  }

  private asiakirjatExcludingDeleted(): Asiakirja[] {
    return (this.koejaksoData.vastuuhenkilonArvio?.asiakirjat ?? []).filter(
      (asiakirja) =>
        !this.deletedAsiakirjat.map((deleted) => deleted.nimi).includes(asiakirja.nimi)
    )
  }

  onFilesAdded(files: File[]) {
    this.addedFiles = [...files, ...this.addedFiles]
    this.newAsiakirjatMapped?.push(...mapFiles(files))
    this.$emit('skipRouteExitConfirm', false)
  }

  async onDeleteLiitetiedosto(asiakirja: Asiakirja) {
    if (asiakirja.id) {
      this.deletedAsiakirjat = [asiakirja, ...this.deletedAsiakirjat]
    } else {
      this.newAsiakirjatMapped = this.newAsiakirjatMapped?.filter(
        (a) => a.nimi !== asiakirja.nimi
      )
      this.addedFiles = this.addedFiles?.filter((a) => a.name !== asiakirja.nimi)
    }
    this.$emit('skipRouteExitConfirm', false)
  }
}
